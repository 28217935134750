import React, { Component } from 'react';
import styles from './Intraduce.module.scss';
import axios from 'axios';
import qs from 'qs';
import { API } from '../../public/utils';
class Introduce extends Component {
	constructor(props) {
		super(props);
		this.state = {
			conment:'',//寺庙简介
		};
	}
	componentDidMount() {
		//获取寺庙简介
		axios({
			method: 'post',
			url: API + '/temple/getTempleIntro',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			data: qs.stringify({
				IMEIID: sessionStorage.getItem('IMEIID')
			})
		}).then((data) => {
			console.log(data);
			if(data.data.code==='ok'){
				this.setState({conment:data.data.data});
			}
		});
	}
	render() {
        const {conment}=this.state;
		return (
			<div style={{ padding: '0.2rem', background: '#fff' }} className={styles.introduce}>
				<h2 style={{ textAlign: 'center', fontSize: '0.18rem' }}>{conment.templeName}简介</h2>
				<div style={{ textIndent: '0.3rem', lineHeight: '0.24rem', fontSize: '0.14rem' }}>
                    <p style={{textAlign:"justify"}}>{conment.templeConment}</p>
                    <img src={conment.templeUrl} alt="暂无图片" style={{ width: '100%' }}/>
				</div>
			</div>
		);
	}
	
}
export default Introduce;
