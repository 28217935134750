import React, { Component } from 'react';
import Introduce from '../introduce/Introduce';
import Baituo from '../baituo/Baituo';
import Home from '../home/Home';
import axios from 'axios';
import { API } from '../../public/utils';
import history from '../../history';
import './index.css';

class Index1 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTab: 'blueTab',
			hidden: false,
			fullScreen: false,
			IMEIID: this.getQueryString('IMEIID') || sessionStorage.getItem('IMEIID'),
			dingdan: this.getQueryString('out_trade_no') || '', //生成订单编号
			isPay: this.getQueryString('isPay') || '', //微信支付是否弹出支付提示
			method: this.getQueryString('method') || '', //支付宝支付是否刷新页面
			code: this.getQueryString('code') || '', //获取公众号授权跳转后的code
			// openid: this.getQueryString('openid') || '', //获取openid：用户唯一标识
			portNumber: this.getQueryString('portNumber') || '', //24pin端口号
			deviceType: this.getQueryString('deviceType') || '', //设备类型
			TabIndex: 0 //加载组件索引
		};
	}

	//使用正则获取地址栏参数
	getQueryString = (name) => {  
		var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
		var r = this.props.location.search.substr(1).match(reg);
		if (r != null) return unescape(r[2]);
		return null;
	};

	componentWillMount() {
		if (this.props.location.search.indexOf('isPay') === -1) {
			this.setState({ isPay: '' });
		}
		sessionStorage.setItem('IMEIID', this.state.IMEIID);
		const { deviceType, portNumber, IMEIID } = this.state;

		if (deviceType) {
			sessionStorage.setItem('deviceType', deviceType);
			sessionStorage.setItem('portNumber', portNumber);
		}
		//官方支付
		var ua = navigator.userAgent.toLowerCase();
		var isWeixin = ua.indexOf('micromessenger') !== -1;
		if (isWeixin) {
			if (!this.state.code) {
				axios({
					method: 'post',
					url: API + '/pay/getCode'
				}).then((data) => {
					window.location.href = data.data;
				});
			}
			sessionStorage.setItem('code', this.state.code);
		}

		//收呗支付代码
		// if (!this.state.openid) {
		// 	axios({
		// 		method: 'get',
		// 		url: API + '/sbpay/getOpenId'
		// 	}).then((data) => {
		// 		// console.log(data);
		// 		// console.log(JSON.stringify(data))
		// 		window.location.href = data.data.data;
		// 	});
		// }
		// sessionStorage.setItem('openid', this.state.openid);
		var sessionType = sessionStorage.getItem('deviceType');
		if (sessionType === '0' || sessionType === '2' || sessionType === '4') {
			history.push({
				pathname: '/setMeal',
				state: { dengList: sessionStorage.getItem('portNumber'), IMEIID: IMEIID }
			});
		}
	}

	render() {
		const { IMEIID, dingdan, isPay, method, TabIndex } = this.state;
		const list = [
			{
				title: '首页',
				icon: 'icon-shouye',
				fontSize: '0.22rem'
			},
			{
				title: '简介',
				icon: 'icon-lvyoujianzhumingshenggujitourism-ribensimiao',
				fontSize: '0.23rem'
			},
			{
				title: '小知识',
				icon: 'icon-shu',
				fontSize: '0.22rem'
			}
		];
		return (
			<div style={{ height: '100%' }}>
				<div className="bigbox" id="container">
					{TabIndex === 0 ? (
						//组件传值，一次性传递多个值
						<Home {...{ IMEIID, isPay, dingdan, method }} />
					) : TabIndex === 1 ? (
						<Introduce />
					) : (
						<Baituo />
					)}
				</div>
				<nav className="nav">
					<ul id="tabs">
						{list.map((item, i) => {
							return (
								<li
									key={item.title}
									onClick={() => this.TabBar(i)}
									style={TabIndex === i ? { color: '#BE9567' } : { color: '#808080' }}
								>
									<i className={`icon iconfont ${item.icon}`} style={{ fontSize: item.fontSize }} />
									<span>{item.title}</span>
								</li>
							);
						})}
					</ul>
				</nav>
			</div>
		);
	}
	//选项卡事件
	TabBar = (index) => {
		this.setState({ TabIndex: index });
	};
}
export default Index1;
