import React, { Component } from 'react';
import { NavBar, Toast, Icon, Modal } from 'antd-mobile';
import styles from './SetMeal.module.scss';
import axios from 'axios';
import qs from 'qs';
import lianhua from '../../images/lianhuadengs.png';
import { API } from '../../public/utils';
import DocumentTitle from 'react-document-title';

function closest(el, selector) {
	const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;
	while (el) {
		if (matchesSelector.call(el, selector)) {
			return el;
		}
		el = el.parentElement;
	}
	return null;
}
//轻提示
function showToast(content) {
	Toast.info(content, 2);
}
const SIGNAL_LIST = [ 1, 2, 3, 4, 5 ];
class SetMeal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			meal: [], //所有套餐
			signal: 0, //设备信号值
			dengList: (props.location.state || {}).dengList || this.getQueryString('portNumber') || '', //点亮设备
			content: (props.location.state || {}).fuStr || '', //祈福语句
			IMEIID: (props.location.state || {}).IMEIID || this.getQueryString('IMEIID') || '', //IMEIID
			name: (props.location.state || {}).name || '', //祈福人姓名
			appointmentId: (props.location.state || {}).appointmentId || '', //预约ID
			selectId: '', //选中套餐ID
			payType: '', //支付方式
			zzType: '', //走账方式  公账：GF   私账：DLB
			isClick: true,
			openid: sessionStorage.getItem('openid') || '', //每个用户的唯一标识
			templeName: '', //寺庙名称
			comboTimes: 'XXX', //满堂红亮灯时间
			modal1: false, //弹窗显示控制
			num: 0, //弹窗随机数
			authCode: this.getQueryString('auth_code') || '', //支付宝支付code码（哆啦宝）
			stateData: this.getQueryString('state') || '' //支付宝授权后获取数据
		};
		this.getQueryString = this.getQueryString.bind(this);
	}
	//使用正则获取地址栏参数
	getQueryString(name) {
		var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
		var r = this.props.location.search.substr(1).match(reg);
		if (r != null) return unescape(r[2]);
		return null;
	}
	//弹框显示
	showModal = (key) => {
		// e.preventDefault(); // 修复 Android 上点击穿透
		this.setState({
			[key]: true
		});
	};
	//弹窗关闭
	onClose = (key) => () => {
		this.setState({
			[key]: false
		});
	};

	onWrapTouchStart = (e) => {
		// fix touch to scroll background page on iOS
		if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
			return;
		}
		const pNode = closest(e.target, '.am-modal-content');
		if (!pNode) {
			e.preventDefault();
		}
	};

	componentDidMount() {
		// const {IMEIID,name,content,appointmentId}=this.state;
		// console.log(IMEIID,name,content,appointmentId);

		//判断走公账或私账
		axios({
			method: 'get',
			url: API + '/DLBPay/payType/' + sessionStorage.getItem('IMEIID')
		}).then((data) => {
			// console.log(data);
			this.setState({ zzType: data.data.data });
		});
		//识别支付宝或微信
		var ua = navigator.userAgent.toLowerCase();
		var isWeixin = ua.indexOf('micromessenger') !== -1;
		if (isWeixin) {
			this.setState({ payType: 'wx' });
			//官方支付静默校验
			axios({
				method: 'post',
				url: API + '/pay/WxConfig',
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
				data: qs.stringify({
					code: sessionStorage.getItem('code'),
					openid: this.state.openid
				})
			}).then((data) => {
				sessionStorage.setItem('openid', data.data.data.openid);
				this.setState({ openid: sessionStorage.getItem('openid') });
				var data1 = data.data.data;
				window.wx.config({
					// debug: true, //开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
					appId: data1.appid, // 必填，公众号的唯一标识
					timestamp: data1.timestamp, // 必填，生成签名的时间戳
					nonceStr: data1.noncestr, // 必填，生成签名的随机串
					signature: data1.sign, // 必填，签名
					jsApiList: [ 'chooseWXPay' ] // 必填，需要使用的JS接口列表
				});
			});
		} else {
			this.setState({ payType: 'zfb' });
		}
		//获取套餐列表
		axios({
			method: 'post',
			url: API + '/combo/getComboList',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			data: qs.stringify({
				IMEIID: sessionStorage.getItem('IMEIID')
			})
		}).then((data) => {
			// console.log(data);
			this.setState({ meal: data.data.data, selectId: data.data.data[0].comboId }, () => {
				this.state.meal.map((item) => {
					if (item.comboType === '0') {
						this.setState({ comboTimes: item.comboTimes });
						return;
					}
				});
			});
		});
		//设备信号值
		axios({
			method: 'post',
			url: API + '/device/getDeviceToSignal',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			data: qs.stringify({
				IMEIID: sessionStorage.getItem('IMEIID')
			})
		}).then((signal) => {
			//设备信号值
			// console.log(signal);
			if (signal.data.code === 'ok') {
				this.setState({
					signal: Number(signal.data.data.Signal),
					code: signal.data.code,
					templeName: signal.data.data.templeName
				});
			}
		});

		// 授权后获取auth_code，请求付款界面(哆啦宝)
		// alert(this.state.stateData);
		if (this.state.authCode !== undefined && this.state.authCode !== '') {
			axios({
				method: 'post',
				url: API + '/DLBPay/DLBZFBPay',
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
				data: qs.stringify({
					state: this.state.stateData,
					auth_code: this.state.authCode
				})
			}).then((data) => {
				// alert(JSON.stringify(data));
				//跳转页面导致数据丢失，所以获取后端传过来的数据，并对状态进行更新
				this.setState({
					name: data.data.data.prayName,
					content: data.data.data.commont
				});

				//支付宝交易号唤起支付
				window.ap.tradePay(
					{
						tradeNO: data.data.data.AlipayNumber
					},
					(res) => {
						// alert(JSON.stringify(res));
						if (res.resultCode === '9000') {
							this.showModal('modal1');
						} else if (res.resultCode === '6001') {
							showToast('用户中途取消');
						} else if (res.resultCode === '4000') {
							showToast('订单支付失败');
						} else if (res.resultCode === '8000') {
							showToast('正在处理中');
						} else if (res.resultCode === '6002') {
							showToast('网络连接出错');
						} else if (res.resultCode === '99') {
							showToast('用户忘记密码');
						}
					}
				);
			});
		}
	}
	componentWillMount() {
		//获取随机数
		const num = Math.floor(Math.random() * 5);
		this.setState({ num });
	}

	render() {
		const { meal, num, signal } = this.state;

		const tcList = [
			{
				bgimg: `url(${require('../../images/tc11.png')})`,
				tcname: [ '0.96rem', '0.685rem', '1.16rem', '0.16rem', '#12C286', '0.16rem' ],
				tcyu: [ '0.92rem', '0.696rem', '0.84rem', '0.23rem', '#12C286', '0.24rem' ]
			},
			{
				bgimg: `url(${require('../../images/tc22.png')})`,
				tcname: [ '0.155rem', '0.38rem', '0.355rem', '0.15rem', '#fff', '0.16rem' ],
				tcyu: [ '0.235rem', '0.68rem', '0.355rem', '0.23rem', '#fff', '0.24rem' ]
			},
			{
				bgimg: `url(${require('../../images/tc33.png')})`,
				tcname: [ '0.15rem', '0.61rem', '0.63rem', '0.15rem', '#14141A', '0.16rem' ],
				tcyu: [ '0.235rem', '0.275rem', '0.405rem', '0.23rem', '#14141A', '0.24rem' ]
			},
			{
				bgimg: `url(${require('../../images/tc44.png')})`,
				tcname: [ '0.15rem', '0.735rem', '0.635rem', '0.15rem', '#14141A', '0.16rem' ],
				tcyu: [ '0.235rem', '0.4rem', '0.38rem', '0.23rem', '#14141A', '0.24rem' ]
			},
			{
				bgimg: `url(${require('../../images/tc55.png')})`,
				tcname: [ '0.92rem', '0.64rem', '0.575rem', '0.15rem', '#A46C43', '0.16rem' ],
				tcyu: [ '0.965rem', '0.63rem', '0.26rem', '0.24rem', '#A46C43', '0.24rem' ]
			}
		];
		// console.log(this.state.content.split(',')[0]);
		return (
			<DocumentTitle title="选择套餐">
				<div className={styles.meal}>
					<div className={styles.header}>
						<NavBar
							mode="light"
							leftContent={[
								<i
									key="1"
									style={{ fontSize: 18, color: '#be9567', fontWeight: 'bolder' }}
									className="icon iconfont icon-miao"
								/>,
								<span key="2" style={{ fontSize: 16, color: '#000', fontWeight: 'bolder' }}>
									{this.state.templeName}
								</span>
							]}
							rightContent={
								<ul id="signal" className={styles.shebei}>
									{SIGNAL_LIST.map((item, index) => {
										return (
											<li
												key={item + index}
												style={
													signal >= 26 && index <= 5 ? (
														{ background: '#333' }
													) : signal >= 21 && index <= 4 ? (
														{ background: '#333' }
													) : signal >= 17 && index <= 3 ? (
														{ background: '#333' }
													) : signal >= 13 && index <= 2 ? (
														{ background: '#333' }
													) : signal >= 6 && index <= 1 ? (
														{ background: '#333' }
													) : (
														{ background: '#ccc' }
													)
												}
											/>
										);
									})}
								</ul>
							}
						/>
					</div>
					<div className={styles.content}>
						<div style={{ background: '#fff' }}>
							<ul className={styles.section_style}>
								{meal.map((item, index) => {
									return item.comboType === '0' ? (
										<li
											key={item.comboId}
											className="lis"
											onClick={this.changeStyle.bind(this, index, item.comboId, item.comboType)}
											style={index === 0 ? { borderColor: '#d41a1a' } : {}}
										>
											<p className={styles.time}>
												<img src={lianhua} alt="" />
												<span style={{ color: '#d41a1a', fontWeight: 'bold' }}>满堂红</span>
											</p>
											<p className={`${styles.money} money`} style={{ color: '#d41a1a' }}>
												￥{item.comboMoney}
											</p>
											<div
												className={`${styles.icons} icons`}
												style={index === 0 ? { background: '#d41a1a' } : {}}
											>
												<Icon type="check" style={{ width: '0.16rem', height: '0.16rem' }} />
											</div>
										</li>
									) : (
										<li
											key={item.comboId}
											className="lis"
											onClick={this.changeStyle.bind(this, index, item.comboId)}
										>
											<p className={styles.time}>
												<img src={lianhua} alt="" />
												{item.comboTimes}分钟
											</p>
											<p className={styles.money}>
												￥{item.comboMoney}/<span style={{ fontSize: 12 }}>对</span>
											</p>
											<div className={`${styles.icons} icons`}>
												<Icon type="check" style={{ width: '0.16rem', height: '0.16rem' }} />
											</div>
										</li>
									);
								})}
							</ul>
						</div>
						<div className={styles.explain}>
							<fieldset style={{ border: '0', borderTop: '1px solid #6e6d6d' }}>
								<legend style={{ textAlign: 'center', padding: '0 0.1rem' }}>温馨提示</legend>
								<div className={styles.text}>
									<p>1、</p>
									<p>
										“满堂红”即指点亮当前设备所有的灯，如选取“满堂红”套餐将点亮
										<span>{this.state.comboTimes}</span>
										分钟；
									</p>
								</div>
								<div className={styles.text}>
									<p>2、</p>
									<p>在使用过程中，如遇到通讯设备故障，插头恶意插拔，所付金额不予退还。</p>
								</div>
							</fieldset>
						</div>
					</div>
					{/* <div className={styles.footer} id="ptext" onClick={this.showModal('modal1')}> */}
					<div className={styles.footer} id="ptext" onClick={this.paymoney}>
						{/* <div className={styles.footer} id="ptext" onClick={this.paymoney1}> */}
						<p className={styles.end}>确认支付</p>
					</div>
					<Modal
						visible={this.state.modal1}
						transparent
						// closable
						maskClosable
						onClose={this.onClose('modal1')}
						footer={[
							{
								text: '',
								onPress: () => {
									this.onClose('modal1')();
								},
								style: { background: 'rgba(255,255,255,0)' }
							}
						]}
						wrapProps={{ onTouchStart: this.onWrapTouchStart }}
						className={styles.tcpage}
					>
						<div
							style={{
								height: num === 0 ? '3.46rem' : '3.25rem',
								background: `${tcList[num].bgimg}`,
								backgroundSize: '100% 100%',
								position: 'relative'
							}}
						>
							<p
								style={{
									position: 'absolute',
									width: `${tcList[num].tcname[0]}`,
									left: `${tcList[num].tcname[1]}`,
									top: `${tcList[num].tcname[2]}`,
									fontSize: `${tcList[num].tcname[3]}`,
									color: `${tcList[num].tcname[4]}`,
									lineHeight: `${tcList[num].tcname[5]}`
								}}
							>
								{this.state.name}
								{/* 方家傲 */}
							</p>
							<p
								style={{
									position: 'absolute',
									width: `${tcList[num].tcyu[0]}`,
									left: `${tcList[num].tcyu[1]}`,
									top: `${tcList[num].tcyu[2]}`,
									fontSize: `${tcList[num].tcyu[3]}`,
									color: `${tcList[num].tcyu[4]}`,
									lineHeight: `${tcList[num].tcyu[5]}`,
									fontFamily: 'FZCuHuoYi-M25S'
								}}
							>
								{/* 心想事成 */}
								{this.state.content !== '' ? this.state.content.split(',')[0] : '心想事成'}
							</p>
							<div
								style={{
									position: 'absolute',
									width: '100%',
									top: `${num === 0 ? '2.6rem' : '2.35rem'}`,
									fontSize: '0.12rem',
									color: '#737373',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center'
								}}
							>
								<fieldset
									style={{
										border: 0,
										borderTop: '1px solid #ccc',
										lineHeight: '0.12rem',
										padding: '0 0.13rem'
									}}
								>
									<legend style={{ padding: '0 0.1rem', lineHeight: '0.28rem' }}>
										{this.state.templeName}
										{/* 仙居寺庙 */}
									</legend>
								</fieldset>
								<p
									style={{
										width: '0.9rem',
										fontSize: '0.12rem',
										color: '#999',
										lineHeight: '0.22rem'
									}}
								>
									<span>眼界高时无碍物</span>
									<span>心源开处有清波</span>
								</p>
							</div>
						</div>
					</Modal>
				</div>
			</DocumentTitle>
		);
	}
	//选中套餐
	changeStyle(index, id, type) {
		var lists = document.getElementsByClassName('lis');
		var icons = document.getElementsByClassName('icons');
		for (var i = 0; i < lists.length; i++) {
			lists[i].style.borderColor = 'rgba(190, 149, 103,0.15)';
			icons[i].style.display = 'none';
		}
		if (type === '0') {
			lists[index].style.borderColor = '#d41a1a';
			icons[index].style.background = '#d41a1a';
		} else {
			lists[index].style.borderColor = 'rgba(190, 149, 103,1)';
		}
		icons[index].style.display = 'block';

		this.setState({ selectId: id });
	}

	//第三方支付
	paymoney = () => {
		var _this = this;
		const {
			dengList,
			content,
			selectId,
			signal,
			payType,
			isClick,
			name,
			appointmentId,
			IMEIID,
			zzType
		} = this.state;

		if (dengList === undefined) {
			showToast('操作异常，请重新扫码');
		} else {
			if (selectId === '') {
				showToast('请选择套餐');
			} else if (signal === 0) {
				showToast('设备故障');
			} else {
				if (isClick) {
					this.setState({ isClick: false });
					if (payType === 'wx') {
						//区分公账私账请求接口路径
						let urlw = '';
						if (zzType === 'GF') {
							urlw = '/pay/WXOfficialPay';
						} else if (zzType === 'DLB') {
							urlw = '/DLBPay/DLBWXPay';
						}
						// 微信支付
						axios({
							method: 'post',
							// url: API + '/pay/WXOfficialPay',
							// url: API + '/DLBPay/DLBWXPay',
							url: API + urlw,
							headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
							data: qs.stringify({
								comboId: selectId,
								prayName: name,
								prayComment: content,
								portNumber: dengList,
								IMEIID: IMEIID,
								openid: this.state.openid,
								appointmentId: appointmentId
							})
						}).then((data) => {
							console.log(data);
							var data1 = data.data.data;
							function onBridgeReady() {
								window.WeixinJSBridge.invoke(
									'getBrandWCPayRequest',
									{
										// debug: true,
										appId: data1.appId, //公众号名称，由商户传入
										timeStamp: data1.timeStamp, //时间戳，自1970年以来的秒数
										nonceStr: data1.nonceStr, //随机串
										package: data1.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
										signType: data1.signType, //微信签名方式：
										paySign: data1.paySign //微信签名
									},
									(res) => {
										if (res.err_msg === 'get_brand_wcpay_request:ok') {
											// showToast('支付成功');
											_this.showModal('modal1');
										} else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
											_this.setState({ isClick: true });
											showToast('支付取消');
										} else if (res.err_msg === 'get_brand_wcpay_request:fail') {
											_this.setState({ isClick: true });
											showToast('支付失败');
										}
									}
								);
							}
							if (typeof window.WeixinJSBridge === 'undefined') {
								if (document.addEventListener) {
									document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
								} else if (document.attachEvent) {
									document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
									document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
								}
							} else {
								onBridgeReady();
							}
						});
					} else if (payType === 'zfb') {
						//哆啦宝支付宝支付授权
						let urlt = '';
						if (zzType === 'GF') {
							urlt = '/pay/zfbCrtAliPay';
						} else if (zzType === 'DLB') {
							urlt = '/DLBPay/authorization';
						}
						axios({
							method: 'post',
							// url: API + '/pay/zfbCrtAliPay',
							// url: API + '/DLBPay/authorization',
							url: API + urlt,
							headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
							data: qs.stringify({
								comboId: selectId,
								prayName: name,
								prayComment: content,
								portNumber: dengList,
								IMEIID: IMEIID,
								appointmentId: appointmentId
							})
						}).then((data) => {
							//官方
							// const div = document.createElement('div');
							// div.innerHTML = data.data; //data.data是返回的表单
							// document.body.appendChild(div);
							// document.forms.punchout_form.submit();
							//哆啦宝
							// window.location.replace(data.data.data);
							if (zzType === 'GF') {
								const div = document.createElement('div');
								div.innerHTML = data.data; //data.data是返回的表单
								document.body.appendChild(div);
								document.forms.punchout_form.submit();
							} else if (zzType === 'DLB') {
								window.location.replace(data.data.data);
							}
						});
					}
					let ss = setTimeout(() => {
						this.setState({ isClick: true });
						if (this.state.Click) {
							clearTimeout(ss);
						}
					}, 5000);
				}
			}
		}
	};
}
export default SetMeal;
