import React, { Component } from 'react';
import styles from './Baituo.module.css';
import gdimg from './gd3.jpg';
class Baituo extends Component {
	render() {
		return (
			<div className={styles.baituo}>
				<div style={{ padding: '0.2rem', background: '#fff' }}>
					<h2 style={{ textAlign: 'center', fontSize: '0.18rem' }}>供灯的十大功德</h2>
					<div style={{ textIndent: '0.3rem', lineHeight: '0.24rem', fontSize: '0.14rem' }}>
						<p>一：照世如灯。供灯者生生世世如同人间的明灯，人中之王。智慧光明照亮整个世界。</p>
						<p>二：肉眼不坏。供灯者心明眼亮，不会变成盲人，也不会近视。</p>
						<p>三：得于天眼。将会获得五眼中的天眼。</p>
						<p>四：善恶智能。能辨别善法和恶法，懂得一切因果取舍。现在世间上许多人极为盲目，不知善恶取舍，而供灯者的智慧超越其他人。</p>
						<p>五：灭除大暗。具有超胜的智慧，能灭除自他相续中的一切愚痴黑暗。</p>
						<p>六：得智能明。自己的智慧超群众人，不受外界的各种诱惑，有辨别取舍的能力。</p>
						<p>七：不在暗处。供灯的人生生世世不会生在黑暗和具有邪见的地方，而住在光明殊胜之地。</p>
						<p>八：具大福报。终生不造罪业，具有修持善法的缘。</p>
						<p>九：命终生天。命终转生天界。即使转世投生人间，也是富贵之人。</p>
						<p>十：速证涅槃。供灯之人很快能证得果位。</p>
						<img src={gdimg} alt="" style={{ width: '100%' }}/>
						<p>愿智慧之灯长明，愿世间不再有那么多的苦难，愿一切有情众生能够离苦得乐，愿这盏盏心灯能够驱逐你内心的恐惧和忧虑。</p>
					</div>
				</div>
			</div>
		);
	}
}
export default Baituo;
