import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Index from './views/Index/Index';
import SetMeal from './views/setmeal/SetMeal';
import history from './history';

function App() {
	return (
			<Router history={history}>
				<Switch>
					<Route path="/setMeal" component={SetMeal} />
					<Route path="/" component={Index} />
					{/* <Route path="/lead" component={Lead} /> */}
					{/* <Route path="/selTemple" component={SelTemple} /> */}
					{/* <Route path="/telDevice" component={TelDevice} /> */}
					
				</Switch>
			</Router>
	);
}

export default App;
