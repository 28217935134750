import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import images from '../../images/2.png';
import styles from './Home.module.scss';
import axios from 'axios';
import qs from 'qs';
import { WhiteSpace, Button, Toast, Modal, Tabs, DatePicker, NoticeBar } from 'antd-mobile';
import history from '../../history';
import lianhuadeng from '../../images/lianhuadeng@3x.png';
import { API } from '../../public/utils';
import Axios from 'axios';

const nowTimeStamp = Date.now();
const now = new Date(nowTimeStamp);
const maxDate = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());
//自定义子标签
const CustomChildren = ({ extra, onClick, children }) => (
	<div onClick={onClick} className={`${styles.xin} ${styles.name}`}>
		{children}
		<span style={{ float: 'right', color: '#888' }}>{extra}</span>
	</div>
);

function closest(el, selector) {
	const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;
	while (el) {
		if (matchesSelector.call(el, selector)) {
			return el;
		}
		el = el.parentElement;
	}
	return null;
}

function showToast(content) {
	Toast.info(content, 2);
}
function failToast(msg) {
	Toast.fail(msg, 2);
}
function successToast(msg) {
	Toast.success(msg, 2);
}
const DENG_LIST = [ '点亮一', '点亮二', '点亮三', '点亮四', '点亮五', '点亮六', '点亮七', '点亮八', '点亮九', '点亮十', '点亮十一', '点亮十二' ];

const SIGNAL_LIST = [ 1, 2, 3, 4, 5 ];

const alert = Modal.alert;

const tabs = [ { title: '点灯' }, { title: '预约' } ];

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			signal: 0,
			sta: {},
			fu: [],
			num: 30,
			dengNum: 0,
			fuStr1: '', //被点击的祈福语句
			fuStr2: '', //确认被点击祈福语句
			IMEIID: props.IMEIID,
			isPay: props.isPay || '',
			method: props.method || '',
			dengList: [],
			code: '',
			modal1: false,
			dingdan: props.dingdan || '',
			templeName: '', //寺庙名称
			fuList: [], //祈福语句列表
			indexList: [], //被点击的祈福语句索引
			checkbox: false, //全选框
			leight: [], //已点亮设备
			domP: [], //存放点亮设备节点
			unUsed: 0, //不可用设备数量
			inputValue: '', //姓名输入框值
			phoneValue: '', //手机号输入框值
			phoneFlag: false, //手机号校验
			date: now,
			time: now,
			dpValue: null,
			customChildValue: null,
			visible: false,
			appointment: [], //预约列表
			tabClick: 0 //tab索引
		};
	}

	componentDidMount() {
		const { IMEIID, dingdan, isPay, method } = this.state;
		//微信支付返回
		if (isPay) {
			// console.log(this.state.dingdan, this.state.isPay);
			axios({
				method: 'post',
				url: API + '/pay/queryOrderStatusToWX',
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
				data: qs.stringify({
					out_trade_no: dingdan
				})
			}).then((data) => {
				// console.log(data);
				if (data.data.code === 'error') {
					failToast(data.data.msg);
				} else {
					successToast(data.data.msg);
				}
				setTimeout(() => {
					this.props.history.push('/?IMEIID=' + IMEIID);
				}, 2000);
			});
		}
		//支付宝支付返回
		if (method) {
			this.props.history.push('/?IMEIID=' + IMEIID);
		}

		//设备信号值
		axios({
			method: 'post',
			url: API + '/device/getDeviceToSignal',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			data: qs.stringify({
				IMEIID: IMEIID
			})
		}).then((signal) => {
			//设备信号值
			console.log(signal);
			if (signal.data.code === 'ok') {
				this.setState({
					signal: Number(signal.data.data.Signal),
					code: signal.data.code,
					templeName: signal.data.data.templeName
				});
			}
		});
		//设备状态
		axios({
			method: 'post',
			url: API + '/device/getDeviceAllPort',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			data: qs.stringify({
				IMEIID: IMEIID
			})
		}).then((sta) => {
			console.log(sta);
			if (sta.data.code === 'ok') {
				//设备端口状态
				const leight = []; //存放点亮的设备
				const { domP } = this.state;
				const newData = JSON.parse(sta.data.data);
				const newdom = (i, styles1, content = '', styles2) => (
					<p key={i} onClick={this.deng.bind(this, i)} style={styles1}>
						<img src={lianhuadeng} alt="" />
						<span style={styles2}>{content || DENG_LIST[i]}</span>
					</p>
				);
				this.setState({ sta: newData }, () => {
					var unUsed = 0; //可不用设备数量
					Object.keys(newData).forEach((key, i) => {
						// console.log(key, newData[key]);
						if (newData[key] === '1') domP.push(newdom(i, { opacity: 0.4 }));
						else if (newData[key] === '4' || newData[key] === '3') {
							unUsed++;
							domP.push(newdom(i, { opacity: 0.4 }, '不可用'));
						} else {
							domP.push(newdom(i, { opacity: 1 }, '已点亮', { color: '#d41a1a' }));
							if (leight.indexOf(key - 1) === -1) leight.push(key - 1);
						}
					});
					this.setState({ unUsed });
				});
				this.setState({ leight });
			}
		});
		//祈福
		axios({
			method: 'post',
			url: API + '/pray/getPrayList'
		}).then((fu) => {
			// console.log(fu);
			if (fu.data.code === 'ok') {
				//祈福语
				this.setState({
					fu: fu.data.data
				});
			}
		});

		setTimeout(() => {
			Toast.hide();
		}, 3000);
	}
	render() {
		const { fuStr1, fuStr2, fu, modal1, domP, signal } = this.state;
		return (
			<div>
				<header>
					<div>
						<img src={images} alt="" />
					</div>
				</header>
				<section>
					<div className={styles.si}>
						<h3>
							<i className="icon iconfont icon-miao" />
							<span>{this.state.templeName}</span>
						</h3>
						<ul id="signal" className={styles.shebei}>
							{SIGNAL_LIST.map((item, index) => {
								return (
									<li
										key={item + index}
										style={
											signal >= 26 && index <= 5 ? (
												{ background: '#333' }
											) : signal >= 21 && index <= 4 ? (
												{ background: '#333' }
											) : signal >= 17 && index <= 3 ? (
												{ background: '#333' }
											) : signal >= 13 && index <= 2 ? (
												{ background: '#333' }
											) : signal >= 6 && index <= 1 ? (
												{ background: '#333' }
											) : (
												{ background: '#ccc' }
											)
										}
									/>
								);
							})}
						</ul>
					</div>
					{/* 选灯模块 */}
					{/* 设备类型为1时（大蜡烛），不用选灯 */}
					{sessionStorage.getItem('deviceType') !== '1' ? (
						<div className={styles.gongdeng} id="denglist">
							<h3>
								<i className="icon iconfont icon-deng" />
								祈福灯
								<div
									style={{
										float: 'right',
										fontWeight: 100,
										fontSize: '0.14rem',
										color: '#d41a1a'
									}}
								>
									<span style={{ float: 'left' }}>满堂红</span>
									<input
										type="checkbox"
										style={{
											margin: '0.06rem 0.04rem 0 0.03rem',
											float: 'right'
										}}
										checked={this.state.checkbox}
										onChange={this.checkbox}
										ref={(input) => {
											this.input = input;
										}}
									/>
								</div>
							</h3>
							<div className={styles.deng} id="deng">
								{domP}
							</div>
						</div>
					) : (
						''
					)}
					{/* 设备类型为1时（大蜡烛），添加预约功能，否者不添加预约 */}
					{sessionStorage.getItem('deviceType') === '1' ? (
						<div style={{ marginTop: '0.15rem' }}>
							<Tabs
								tabs={tabs}
								initialPage={0}
								tabBarUnderlineStyle={{ border: '1px solid #d41a1a' }}
								tabBarActiveTextColor="#d41a1a"
								tabBarInactiveTextColor="#be9567"
								// onChange={(tab, index) => {
								// 	console.log('onChange', index, tab);
								// }}
								onTabClick={this.tabClick}
							>
								<div>
									<div className={styles.fu} style={{ marginTop: 0 }}>
										<h3>祈福心愿</h3>
										<div className={styles.select}>
											<div className={styles.name}>
												<span>祈福愿者：</span>
												<input
													type="text"
													placeholder="请输入您的姓名"
													value={this.state.inputValue}
													ref="fuser1"
													onBlur={this.blur}
													onChange={this.inputChange}
												/>
											</div>
											<div className={`${styles.xin} ${styles.name}`}>
												<span>祈福心愿：</span>
												<div className={styles.yuan} onClick={() => this.showModal('modal1')}>
													{fuStr2}
												</div>
											</div>
										</div>

										<Modal
											visible={modal1}
											transparent
											maskClosable={true}
											style={{ width: '2.71rem' }}
											title="请选择心愿"
											footer={[
												{
													text: (
														<div
															style={{
																width: '2.41rem',
																height: '0.3rem',
																lineHeight: '0.3rem',
																position: 'absolute',
																top: '0',
																left: '0',
																right: '0',
																bottom: '0',
																margin: 'auto',
																borderRadius: '0.13rem',
																background: '#BE9567',
																color: '#fff',
																fontSize: '0.16rem'
															}}
														>
															确定
														</div>
													),
													onPress: () => {
														this.setState({ fuStr2: fuStr1 });
														this.showModal('modal1');
													}
												}
											]}
											wrapProps={{ onTouchStart: this.onWrapTouchStart }}
											className={styles.tcsel}
										>
											<div
												className="list"
												style={{
													height: '2.84rem',
													overflow: 'scroll',
													color: '#19191A',
													fontSize: '0.12rem',
													display: 'flex',
													justifyContent: 'space-between',
													flexWrap: 'wrap'
												}}
											>
												{fu.map((item, index) => {
													var indexSelect; //默认选中祈福索引
													this.state.fuStr2.split(',').map((items) => {
														if (item.prayCommont === items) {
															indexSelect = index;
														}
													});
													return (
														<p
															key={item.prayId}
															style={
																indexSelect === index ? (
																	{
																		width: '0.72rem',
																		height: '0.24rem',
																		background: '#BE9567',
																		color: '#fff',
																		lineHeight: '0.24rem',
																		borderRadius: '0.12rem',
																		margin: '0.1rem 0'
																	}
																) : (
																	{
																		width: '0.72rem',
																		height: '0.24rem',
																		background: '#E6E6E6',
																		lineHeight: '0.24rem',
																		borderRadius: '0.12rem',
																		margin: '0.1rem 0'
																	}
																)
															}
															onClick={this.fu.bind(this, index)}
														>
															{item.prayCommont}
														</p>
													);
												})}
											</div>
										</Modal>
									</div>
									<div className={styles.button}>
										<Button
											type="warning"
											style={{
												background: '#d41a1a',
												borderRadius: '0.3rem',
												height: '0.39rem',
												lineHeight: '0.39rem'
											}}
											onClick={this.toMeal}
										>
											点亮心灯
										</Button>
										<WhiteSpace />
									</div>
								</div>
								<div>
									<NoticeBar marqueeProps={{ loop: true, style: { padding: '0 7.5px' } }}>
										{/* Notice:*/}
										{this.state.appointment.map((item, index) => {
											return item.appointmentStatus === '1' ? (
												<span key={item.appointmentId}>
													{' '}
													用户{item.appointmentPhoneNum.substr(0, 3) +
														'****' +
														item.appointmentPhoneNum.substr(7)}正在排队；{' '}
												</span>
											) : (
												<span key={item.appointmentId}>
													用户{item.appointmentPhoneNum.substr(0, 3) +
														'****' +
														item.appointmentPhoneNum.substr(7)}正在点亮；{' '}
												</span>
											);
										})}
									</NoticeBar>
									<div className={styles.fu} style={{ marginTop: 0 }}>
										<h3>祈福心愿</h3>
										<div className={styles.select}>
											<div className={styles.name}>
												<span>祈福愿者：</span>
												<input
													type="text"
													placeholder="请输入您的姓名"
													value={this.state.inputValue}
													ref="fuser"
													onBlur={this.blur}
													onChange={this.inputChange}
												/>
											</div>

											<div className={`${styles.xin} ${styles.name}`}>
												<span>联系方式：</span>
												<input
													type="number"
													placeholder="请输入您的手机号"
													value={this.state.phoneValue}
													ref="phone"
													onBlur={this.blurPhone}
													onChange={this.changePhone}
												/>
											</div>
											<DatePicker
												mode="date"
												title="选择时间"
												minDate={now}
												maxDate={maxDate}
												extra="Optional"
												value={this.state.date}
												onChange={(date) => this.setState({ date })}
											>
												<CustomChildren>预约时间：</CustomChildren>
											</DatePicker>
											<div className={`${styles.xin} ${styles.name}`}>
												<span>祈福心愿：</span>
												<div className={styles.yuan} onClick={() => this.showModal('modal1')}>
													{fuStr2}
												</div>
											</div>
										</div>

										<Modal
											visible={modal1}
											transparent
											maskClosable={true}
											style={{ width: '2.71rem' }}
											title="请选择心愿"
											footer={[
												{
													text: (
														<div
															style={{
																width: '2.41rem',
																height: '0.3rem',
																lineHeight: '0.3rem',
																position: 'absolute',
																top: '0',
																left: '0',
																right: '0',
																bottom: '0',
																margin: 'auto',
																borderRadius: '0.13rem',
																background: '#BE9567',
																color: '#fff',
																fontSize: '0.16rem'
															}}
														>
															确定
														</div>
													),
													onPress: () => {
														this.setState({ fuStr2: fuStr1 });
														this.showModal('modal1');
													}
												}
											]}
											wrapProps={{ onTouchStart: this.onWrapTouchStart }}
										>
											<div
												className="list"
												style={{
													height: '2.84rem',
													overflow: 'scroll',
													color: '#19191A',
													fontSize: '0.12rem',
													display: 'flex',
													justifyContent: 'space-between',
													flexWrap: 'wrap'
												}}
											>
												{fu.map((item, index) => {
													var indexSelect; //默认选中祈福索引
													this.state.fuStr2.split(',').map((items) => {
														if (item.prayCommont === items) {
															indexSelect = index;
														}
													});
													return (
														<p
															key={item.prayId}
															style={
																indexSelect === index ? (
																	{
																		width: '0.72rem',
																		height: '0.24rem',
																		background: '#BE9567',
																		color: '#fff',
																		lineHeight: '0.24rem',
																		borderRadius: '0.12rem',
																		margin: '0.1rem 0'
																	}
																) : (
																	{
																		width: '0.72rem',
																		height: '0.24rem',
																		background: '#E6E6E6',
																		lineHeight: '0.24rem',
																		borderRadius: '0.12rem',
																		margin: '0.1rem 0'
																	}
																)
															}
															onClick={this.fu.bind(this, index)}
														>
															{item.prayCommont}
														</p>
													);
												})}
											</div>
										</Modal>
									</div>
									<div className={styles.button}>
										<Button
											type="warning"
											style={{
												background: '#d41a1a',
												borderRadius: '0.3rem',
												height: '0.39rem',
												lineHeight: '0.39rem'
											}}
											onClick={this.buttonYY}
										>
											确定
										</Button>
										<WhiteSpace />
									</div>
								</div>
							</Tabs>
							<WhiteSpace />
						</div>
					) : (
						<div>
							<div className={styles.fu}>
								<h3>祈福心愿</h3>
								<div className={styles.select}>
									<div className={styles.name}>
										<span>祈福愿者：</span>
										<input
											type="text"
											placeholder="请输入您的姓名"
											value={this.state.inputValue}
											ref="fuser"
											onBlur={this.blur}
											onChange={this.inputChange}
										/>
									</div>
									<div className={`${styles.xin} ${styles.name}`}>
										<span>祈福心愿：</span>
										<div className={styles.yuan} onClick={() => this.showModal('modal1')}>
											{fuStr2}
										</div>
									</div>
								</div>

								<Modal
									visible={modal1}
									transparent
									maskClosable={true}
									style={{ width: '2.71rem' }}
									title="请选择心愿"
									footer={[
										{
											text: (
												<div
													style={{
														width: '2.41rem',
														height: '0.3rem',
														lineHeight: '0.3rem',
														position: 'absolute',
														top: '0',
														left: '0',
														right: '0',
														bottom: '0',
														margin: 'auto',
														borderRadius: '0.13rem',
														background: '#BE9567',
														color: '#fff',
														fontSize: '0.16rem'
													}}
												>
													确定
												</div>
											),
											onPress: () => {
												this.setState({ fuStr2: fuStr1 });
												this.showModal('modal1');
											}
										}
									]}
									wrapProps={{ onTouchStart: this.onWrapTouchStart }}
								>
									<div
										className="list"
										style={{
											height: '2.84rem',
											overflow: 'scroll',
											color: '#19191A',
											fontSize: '0.12rem',
											display: 'flex',
											justifyContent: 'space-between',
											flexWrap: 'wrap'
										}}
									>
										{fu.map((item, index) => {
											var indexSelect; //默认选中祈福索引
											this.state.fuStr2.split(',').map((items) => {
												if (item.prayCommont === items) {
													indexSelect = index;
												}
											});
											return (
												<p
													key={item.prayId}
													style={
														indexSelect === index ? (
															{
																width: '0.72rem',
																height: '0.24rem',
																background: '#BE9567',
																color: '#fff',
																lineHeight: '0.24rem',
																borderRadius: '0.12rem',
																margin: '0.1rem 0'
															}
														) : (
															{
																width: '0.72rem',
																height: '0.24rem',
																background: '#E6E6E6',
																lineHeight: '0.24rem',
																borderRadius: '0.12rem',
																margin: '0.1rem 0'
															}
														)
													}
													onClick={this.fu.bind(this, index)}
												>
													{item.prayCommont}
												</p>
											);
										})}
									</div>
								</Modal>
							</div>
							<div className={styles.button}>
								<Button
									type="warning"
									id="hh"
									style={{
										background: '#d41a1a',
										borderRadius: '0.3rem',
										height: '0.39rem',
										lineHeight: '0.39rem'
									}}
									onClick={this.toMeal}
								>
									点亮心灯
								</Button>
								<WhiteSpace />
							</div>
						</div>
					)}
				</section>
			</div>
		);
	}

	tabClick = (tab, index) => {
		// console.log('tabClick', index, tab);
		this.setState({ tabClick: index, inputValue: '', phoneValue: '' }, () => {
			if (index === 1) {
				Axios({
					method: 'post',
					url: API + '/appointment/getAppointmentList',
					data: qs.stringify({
						IMEIID: this.state.IMEIID
					})
				}).then((data) => {
					// console.log(data);
					if (data.data.code === 'ok') {
						this.setState({ appointment: data.data.data });
					}
				});
			}
		});
	};
	//预约确定
	buttonYY = () => {
		const { IMEIID, inputValue, phoneValue, date, fuStr2, phoneFlag } = this.state;
		const month = date.getMonth() + 1;
		const selDate = date.getFullYear() + '-' + month + '-' + date.getDate();
		const characterTest = new RegExp('^[\u4E00-\u9FA5]{0,6}$');

		if (!characterTest.test(inputValue) && inputValue.length !== 0) {
			showToast('祈愿者姓名只能输入汉字');
		} else if (phoneValue === '') {
			showToast('手机号不能为空');
		} else if (!phoneFlag) {
			showToast('请检查手机号');
		} else {
			Axios({
				method: 'post',
				url: API + '/appointment/checkAppointment',
				data: qs.stringify({
					IMEIID: IMEIID,
					appointmentPhoneNum: phoneValue,
					appointmentStartTime: selDate,
					appointmentContent: inputValue + '&' + fuStr2
				})
			}).then((data) => {
				console.log(data);
				if (data.data.code === 'ok') {
					history.push({
						pathname: '/setMeal',
						state: {
							fuStr: fuStr2,
							IMEIID: IMEIID,
							name: inputValue,
							appointmentId: data.data.data
						}
					});
				} else {
					showToast(data.data.msg);
				}
			});
		}
	};
	//手机号输入框改变
	changePhone = () => {
		this.setState({ phoneValue: this.refs.phone.value });
	};
	//输入手机号失去焦点校验
	blurPhone = () => {
		// console.log(this.state.phoneValue);
		var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
		if (!myreg.test(this.state.phoneValue)) {
			showToast('请检查手机号');
			this.setState({ phoneFlag: false });
		} else {
			// return true;
			this.setState({ phoneFlag: true });
		}
	};
	//input输入框改变事件
	inputChange = () => {
		this.setState(
			{
				inputValue:
					sessionStorage.getItem('deviceType') === '1' && this.state.tabClick === 0
						? this.refs.fuser1.value
						: this.refs.fuser.value
			},
			() => {
				const { inputValue } = this.state;
				//兼容苹果输入法
				var characterTest = new RegExp('[a-z]');
				if (!characterTest.test(inputValue)) {
					//输入字符不能超过6个
					if (inputValue.length >= 7) {
						showToast('最多输入6个字');
						this.setState({
							inputValue: inputValue.substring(0, 6)
						});
					}
				}
			}
		);
	};
	// 弹出层事件
	showModal = (key) => {
		const { modal1 } = this.state;
		this.setState(
			{
				[key]: !modal1
			},
			() => this.blur()
		);
	};

	onWrapTouchStart = (e) => {
		// fix touch to scroll background page on iOS
		if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
			return;
		}
		const pNode = closest(e.target, '.am-modal-content');
		if (!pNode) {
			e.preventDefault();
		}
	};
	//选中所有设备(满堂红)
	checkbox = (e) => {
		var icon = document.getElementById('deng').getElementsByTagName('p');
		var dengList2 = [];
		this.setState({ checkbox: !this.state.checkbox }, () => {
			// 控制所有设备点亮
			if (this.state.checkbox) {
				for (let i = 0; i < icon.length; i++) {
					if (icon[i].getElementsByTagName('span')[0].innerHTML === '已点亮') {
						icon[i].style.borderColor = '#ccc';
					} else if (icon[i].getElementsByTagName('span')[0].innerHTML === '不可用') {
						icon[i].style.opacity = 0.4;
					} else {
						dengList2.push(i + 1);
						icon[i].style.opacity = 1;
						this.setState({ dengList: dengList2 });
					}
				}
			} else {
				//控制所有设备不点亮
				for (let i = 0; i < icon.length; i++) {
					if (icon[i].getElementsByTagName('span')[0].innerHTML === '已点亮') {
						icon[i].style.borderColor = '#ccc';
					} else {
						icon[i].style.opacity = 0.4;
						this.setState({ dengList: [] });
					}
				}
			}
		});
	};
	//给每一个设备显示选中状态
	deng(index) {
		var icon = document.getElementById('deng').getElementsByTagName('p');
		if (icon[index].getElementsByTagName('span')[0].innerHTML === '已点亮') {
			icon[index].style.borderColor = '#ccc';
		} else if (icon[index].getElementsByTagName('span')[0].innerHTML === '不可用') {
			icon[index].style.opacity = 0.4;
			showToast('设备不可用');
		} else {
			var dengList1 = this.state.dengList;
			var i = dengList1.indexOf(index + 1);
			// 已选中的灯取消选中
			if (i === -1) {
				dengList1.push(index + 1);
				icon[index].style.opacity = 1;
			} else {
				icon[index].style.opacity = 0.4;
				dengList1.splice(i, 1);
			}
			this.setState({ dengList: dengList1 }, () => {
				const { leight, unUsed } = this.state;
				if (this.state.dengList.length === 12 - leight.length - unUsed) {
					this.setState({ checkbox: true });
				} else {
					this.setState({ checkbox: false });
				}
			});
		}
	}

	//点击祈福语句
	fu(index) {
		if (sessionStorage.getItem('deviceType') === '1') {
			var list = document.getElementsByClassName('list')[1].getElementsByTagName('p');
		} else {
			var list = document.getElementsByClassName('list')[0].getElementsByTagName('p');
		}
		for (var i = 0; i < list.length; i++) {
			list[i].style.background = '#E6E6E6';
			list[i].style.color = '#19191A';
		}
		var fuList = this.state.fuList; //被点击的祈福语句
		var indexList = this.state.indexList; //被点击的祈福语句的索引
		//判断数组中是否存在，如果不存在，判断数组长度是否大于2，如果存在，直接删除
		if (fuList.indexOf(this.state.fu[index].prayCommont) === -1) {
			//设备1允许多选，其他设备不允许多选
			if (sessionStorage.getItem('deviceType') === '1') {
				//数组长度大于等于2，先删除第一个再添加；确保数组中的值不大于2个
				if (fuList.length >= 2) {
					fuList.splice(0, 1);
					indexList.splice(0, 1);
				}
			} else {
				//数组长度大于等于1，先删除第一个再添加；确保数组中的值不大于1个
				if (fuList.length >= 1) {
					fuList.splice(0, 1);
					indexList.splice(0, 1);
				}
			}
			fuList.push(this.state.fu[index].prayCommont);
			indexList.push(index);
		} else {
			indexList.splice(indexList.indexOf(index), 1);
			fuList.splice(fuList.indexOf(this.state.fu[index].prayCommont), 1);
		}

		this.setState({ fuList, indexList }, () => {
			this.setState({ fuStr1: this.state.fuList.join(',') }, () => {
				//给每一个被选中的祈福语句添加选中样式
				for (let i = 0; i < this.state.indexList.length; i++) {
					list[this.state.indexList[i]].style.background = '#BE9567';
					list[this.state.indexList[i]].style.color = '#fff';
				}
				// console.log(this.state.fuStr1);
			});
		});
	}

	//跳转到套餐页面
	toMeal = () => {
		const { dengList, fuStr2, IMEIID, inputValue } = this.state;
		const characterTest = new RegExp('^[\u4E00-\u9FA5]{0,6}$');

		if (sessionStorage.getItem('deviceType') !== '1' && this.state.dengList.length === 0) {
			showToast('请选择点亮蜡烛');
			return;
		}
		if (this.state.code !== 'ok') {
			showToast('设备故障');
		} else if (!characterTest.test(inputValue) && inputValue.length !== 0) {
			showToast('祈愿者姓名只能输入汉字');
		} else {
			history.push({
				pathname: '/setMeal',
				state: {
					dengList: dengList.join(','),
					fuStr: fuStr2,
					IMEIID: IMEIID,
					name: inputValue
				}
			});
		}
	};

	//输入框失去焦点
	blur = () => {
		let scrollTop = document.body.scrollTop + document.documentElement.scrollTop;
		window.scrollTo(0, scrollTop);
	};
}
export default withRouter(Home);
